<template>
  <b-button-group class="mx-1">
    <b-btn
      v-for="character of characters"
      :key="character"
      variant="primary"
      @click="changed(character)"
      >{{ character }}</b-btn
    >
  </b-button-group>
</template>

<script>
export default {
  name: "SpecialCharacterSelect",
  props: {
    characterSet: {
      type: String,
      default: "·×þðæøœǫʀ¶…"
    }
  },
  data() {
    return {
      characters: this.characterSet.split("")
    };
  },
  methods: {
    changed: function(character) {
      this.$emit("change", character);
    }
  }
};
</script>

<style></style>
